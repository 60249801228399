@import "../../styles/colors";

.clickable-text {
  color: $color-label-grey;
  margin: 0.5rem 0;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
}

.clickable-text:hover {
  color: $color-primary;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 50px;
  height: 50px;
  border: 10px solid #fff;
  border-top: 10px solid #000;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}
