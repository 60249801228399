$color-primary: #f27200;
$color-secondary: #404040;

$background-color-0: #0c0e25;
$background-color-1: #14171f;
$background-color-2: #1f2532;
$background-color-3: #252c3c;
$background-color-4: #00000033;

$color-dark: #161c2a;
$color-selected: #1b91a0;
$color-links: #555;
$color-light: #f0f1f1;
$color-button: $color-primary;

$color-turquise: #00a3ae;

$color-label-grey: #9b9b9b;

$control-background: #2f394d;

$timeline-controls-button-hover-background: #f7931e;

$homepage-button-background: $color-primary;

$color-state-2: #00a3ae;
$color-state-3: #b50117;
$color-state-5: #fdd300;


$color-timeline-button: #9b9b9b;
$color-timeline-button-disabled: $color-secondary;
$color-timeline-button-active: #f9a629;
