@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed");
@import "colors";
@import "@cuttingroom/core-components/dist/vimond-core-components.css";

html {
  font-size: 1em;
}

body,
input {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

body {
  color: $color-label-grey;
  margin: 0;
  font-size: 12px;
  background: black;
  background: url("../assets/img/aurora.jpg") no-repeat center center fixed;
  background-size: 100% 100%;
}
