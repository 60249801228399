@import "../../styles/_colors.scss";

.login-screen {
  $sso-btn-background: #0091df;
  $input-field-width: 300px;

  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 100vh;
  background-color: $background-color-4;

  .logo {
    width: 270px;
    height: 170px;
    background-size: contain;
    margin: 0 auto 0px;
  }

  .sso-login {
    width: $input-field-width;
    margin-top: 3rem;
    button {
      width: 100%;
      background: $sso-btn-background;
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    width: $input-field-width;

    .workspace,
    .mfa,
    .button,
    .dropdown,
    .password {
      margin-top: 0.7rem;
    }

    .tenant-selection {
      display: flex;
      flex-direction: column;
      align-items: center;

      .tenant-list {
        display: flex;
        flex-direction: column;
        width: 75%;

        .tenant-item {
          height: 1rem;
          margin: 0.5rem 0;
        }
      }

      .change-email {
        height: 1rem;
        margin-top: 1rem;
      }
    }

    .qrcode-div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mfa,
      .button {
        margin-top: 1rem;
        width: 300px;
      }
    }

    .password {
      .core-notice-message {
        color: $color-state-3;
      }
    }
  }

  .secondary-login-form {
    display: grid;
    overflow: hidden;

    .content {
      display: grid;
      grid-gap: 0.5rem 2rem;
    }

    &.form-expanded {
      .content {
        padding: 16px 0;
        opacity: 1;
      }
    }

    &.form-collapsed {
      .content {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
  }
}
