@import "../../styles/colors";

.landing-page-bg {
  $input-field-width: 300px;
  
  .confirm-recover-pass-input-form {
    display: flex;
    flex-direction: column;
    width: $input-field-width;

    .email,
    .password,
    .button {
      margin-top: 1rem;
    }

    .password {
      .core-notice-message {
        color: $color-state-3;
      }
    }
  }

  .success-message {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;

    button {
      margin-top: 2rem;
    }
  }
}
