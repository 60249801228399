@import "../../styles/colors";

.landing-page-bg {
  $sso-btn-background: #0091df;
  $input-field-width: 300px;

  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 100vh;
  background-color: $background-color-4;

  .logo {
    width: 270px;
    height: 170px;
    background-size: contain;
    margin: 0 auto 0px;
  }
}
